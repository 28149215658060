module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PCQHHHJ","includeInDevelopment":false,"dataLayerName":"dataLayer","defaultDataLayer":{"type":"function","value":"function(){return{pageType:\"undefined\"!=typeof document?document.querySelector('meta[name=\"pageType\"]')?.content:\"\",platform:\"gatsby\"}}"},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
